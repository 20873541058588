import { t } from "i18next";
import { useTranslation } from "react-i18next";

export const inp_lessee_type = {
  "Persoana fizica": "bcr.PersonType.CIVIL",
  "Persoana juridica": "bcr.PersonType.LEGAL",
};
// { value: "bcr.PersonType.CIVIL", label: t("bcr.PersonType.CIVIL")}

// pre submita
// preod -> kod -> rumunski -> submit

export const inp_vehicle_state = {
  Nou: "bcr.Vehicle.NEW",
  "Second-hand": "bcr.Vehicle.USED",
};

// Categories home page

// Categories
export const inp_vehicle_category = {
  // Toate: "bcr.Category.TOATE",
  // "RO - BCRL (extern)": "bcr.Category.RO - BCRL (extern)",
  // "RO - BCRL (intern)": "bcr.Category.RO - BCRL (intern)",
  // "BCRL (intern) - Contracte Noi": "bcr.Category.BCRL (intern) - Contracte Noi",
  // "RO - BCRL (intern) - No Reînnoit":
  //   "bcr.Category.RO - BCRL (intern) - No Reî",
  // "RO - BCRL (intern) - Reînnoit": "bcr.Category.RO - BCRL (intern) - Reînnoit",
  Autoturism: "bcr.Category.TOURISM",
  "Autovehicul comercial": "bcr.Category.COMERCIAL",
  "Autovehicul pentru transport persoane": "bcr.Category.TRANSPORTING",
  Autotractor: "bcr.Category.FARM",
  Autorulota: "bcr.Category.CAMP",
  "Motocicluri si motocvatricicluri": "bcr.Category.MOTORCYCLES",
  "Remorca si semiremorca": "bcr.Category.TRAILERS_SEMI-TRAILERS",
  "Remorca si semiremorca cisterna":
    "bcr.Category.TRAILERS_SEMI-TRAILERS_CISTERN",
  "Vehicul cisterna/siloz/frigorific": "bcr.Category.MIXEDVEHICLE",
  "Combine, tractoare rutiere, masini si utilaje agricole supuse/nesupuse inmatricularii":
    "bcr.Category.AGRICULTURAL",
  "Masini si utilaje agricole nepropulsate":
    "bcr.Category.AGRICULTURAL_NON-PROPELLED",
  "Tractor rutier": "bcr.Category.TRACTOR_ROAD",
  "Semiremorca frigorifica": "bcr.Category.REFIGERATED_TRAILERS",
};

// Subcategories
export const inp_vehicle_subcategory = {
  Autoturism: "bcr.SubCategory.AUTOTURISM",
  "Automobil mixt": "bcr.SubCategory.AUTOMOBIL_MIXED",
  "Autoturism de teren": "bcr.SubCategory.OFF-ROAD",
  Autobasculanta: "bcr.SubCategory.DUMPER",
  Autobetoniera: "bcr.SubCategory.MIXER_TRUCK",
  Autocamion: "bcr.SubCategory.LORRY",
  Autocisterna: "bcr.SubCategory.TANK",
  Autoexcavator: "bcr.SubCategory.AUTOEXCAVATOR",
  Autofurgon: "bcr.SubCategory.MOTORVAN",
  Autogreder: "bcr.SubCategory.SELF_GRADER",
  Autoincarcator: "bcr.SubCategory.LOADING_MEMBER",
  Automacara: "bcr.SubCategory.CRANE",
  Autospeciala: "bcr.SubCategory.TRUCK",
  Autospecializata: "bcr.SubCategory.SELF-SPECIALIZED",
  Autoutilitara: "bcr.SubCategory.VAN",
  Basculanta: "bcr.SubCategory.TIPPER",
  Buldoexcavator: "bcr.SubCategory.BACKHOE",
  Camion: "bcr.SubCategory.TRUCK_CAMION",
  Furgon: "bcr.SubCategory.FURGON",
  ATV: "bcr.SubCategory.ATV",
  Moped: "bcr.SubCategory.MOPED",
  Motocicleta: "bcr.SubCategory.MOTORCYCLE",
  Scuter: "bcr.SubCategory.MOTOR_SCOOTER",
  Autobuz: "bcr.SubCategory.BUS",
  "Autobuz articulat": "bcr.SubCategory.ARTICULATED_BUS",
  "Autobuz special": "bcr.SubCategory.BUS_SPECIAL",
  Autocar: "bcr.SubCategory.AUTOCAR",
  Microbuz: "bcr.SubCategory.MINIBUS",
  Tramvai: "bcr.SubCategory.TRAM",
  Troleibuz: "bcr.SubCategory.TROLLEYBUS",
  Autorulota: "bcr.SubCategory.CAMP",
  "Tractor rutier": "bcr.SubCategory.ROAD_TRACTOR",
  "Tractor agricol": "bcr.SubCategory.AGRICULTURE_TRACTOR",
  Autotractor: "bcr.SubCategory.AUTOTRACTOR",
  "Cap tractor": "bcr.SubCategory.CAP_TRACTOR",
  "Autotractor cu sa": "bcr.SubCategory.AUTO_TRACTOR_WITH_ITS",
  Remorca: "bcr.SubCategory.TOW",
  Rulota: "bcr.SubCategory.CAMPER_VAN",
  Semiremorca: "bcr.SubCategory.SEMI_TOW",
  Utilaje: "bcr.SubCategory.MACHINERY",
};

// Vehicle Brands
export const inp_vehicle_brand = {
  Abarth: "bcr.Vehicle.TYPE_ABARTH",
  Achleitner: "bcr.Vehicle.TYPE_ACHLEITNER",
  Adler: "bcr.Vehicle.TYPE_ADLER",
  Adly: "bcr.Vehicle.TYPE_ADLY",
  Aebi: "bcr.Vehicle.TYPE_AEBI",
  Aeon: "bcr.Vehicle.TYPE_AEON",
  Aero: "bcr.Vehicle.TYPE_AERO",
  AGCO: "bcr.Vehicle.TYPE_AGCO",
  Aixam: "bcr.Vehicle.TYPE_AIXAM",
  AJS: "bcr.Vehicle.TYPE_AJS",
  Aleko: "bcr.Vehicle.TYPE_ALEKO",
  "Alfa Romeo": "bcr.Vehicle.TYPE_ALFA_ROMEO",
  Allard: "bcr.Vehicle.TYPE_ALLARD",
  Alpha: "bcr.Vehicle.TYPE_ALPHA",
  AMC: "bcr.Vehicle.TYPE_AMC",
  AMS: "bcr.Vehicle.TYPE_AMS",
  Anssems: "bcr.Vehicle.TYPE_ANSSEMS",
  "Antonio Carraro": "bcr.Vehicle.TYPE_ANTONIO_CARRARO",
  Aprillia: "bcr.Vehicle.TYPE_APRILLIA",
  "Arctic Cat": "bcr.Vehicle.TYPE_ARCTIC CAT",
  ARGO: "bcr.Vehicle.TYPE_ARGO",
  Ariel: "bcr.Vehicle.TYPE_ARIEL",
  Aro: "bcr.Vehicle.TYPE_ARO",
  "Aston Martin": "bcr.Vehicle.TYPE_ASTON_MARTIN",
  Atlas: "bcr.Vehicle.TYPE_ATLAS",
  "ATP BUS": "bcr.Vehicle.TYPE_ATP_BUS",
  ATV: "bcr.Vehicle.TYPE_ATV",
  "AU DKW": "bcr.Vehicle.TYPE_AU DKW",
  Audi: "bcr.Vehicle.TYPE_AUDI",
  Austin: "bcr.Vehicle.TYPE_AUSTIN",
  Avia: "bcr.Vehicle.TYPE_AVIA",
  AWE: "bcr.Vehicle.TYPE_AWE",
  AWZ: "bcr.Vehicle.TYPE_AWZ",
  Baotian: "bcr.Vehicle.TYPE_BAOTIAN",
  Barkas: "bcr.Vehicle.TYPE_BARKAS",
  Barossa: "bcr.Vehicle.TYPE_BAROSSA",
  Bashan: "bcr.Vehicle.TYPE_BASHAN",
  Bedford: "bcr.Vehicle.TYPE_BEDFORD",
  Beeline: "bcr.Vehicle.TYPE_BEELINE",
  Bellier: "bcr.Vehicle.TYPE_BELLIER",
  Benalu: "bcr.Vehicle.TYPE_BENALU",
  Benimar: "bcr.Vehicle.TYPE_BENIMAR",
  Benelli: "bcr.Vehicle.TYPE_BENELLI",
  Bentley: "bcr.Vehicle.TYPE_BENTLEY",
  Berger: "bcr.Vehicle.TYPE_BERGER",
  Benzhou: "bcr.Vehicle.TYPE_BENZHOU",
  Beta: "bcr.Vehicle.TYPE_BETA",
  Bianchi: "bcr.Vehicle.TYPE_BIANCHI",
  Bimota: "bcr.Vehicle.TYPE_BIMOTA",
  Binder: "bcr.Vehicle.TYPE_BINDER",
  Blata: "bcr.Vehicle.TYPE_BLATA",
  BMW: "bcr.Vehicle.TYPE_BMW",
  BMC: "bcr.Vehicle.TYPE_BMC",
  Bockmann: "bcr.Vehicle.TYPE_BOCKMANN",
  Bombardier: "bcr.Vehicle.TYPE_BOMBARDIER",
  Boom: "bcr.Vehicle.TYPE_BOOM",
  Borgward: "bcr.Vehicle.TYPE_BORGWARD",
  "Boss Hoss": "bcr.Vehicle.TYPE_BOSS_HOSS",
  Bova: "bcr.Vehicle.TYPE_BOVA",
  Brantner: "bcr.Vehicle.TYPE_BRANTNER",
  Braud: "bcr.Vehicle.TYPE_BRAUD",
  "British Leyland": "bcr.Vehicle.TYPE_BRITISH_LEYLAND",
  Broshuis: "bcr.Vehicle.TYPE_BROSHUIS",
  BSA: "bcr.Vehicle.TYPE_BSA",
  Buell: "bcr.Vehicle.TYPE_BUELL",
  Bugatti: "bcr.Vehicle.TYPE_BUGATTI",
  Buick: "bcr.Vehicle.TYPE_BUICK",
  Bull: "bcr.Vehicle.TYPE_BULL",
  Bultaco: "bcr.Vehicle.TYPE_BULTACO",
  Buyang: "bcr.Vehicle.TYPE_BUYANG",
  Cadillac: "bcr.Vehicle.TYPE_CADILLAC",
  Cagiva: "bcr.Vehicle.TYPE_CAGIVA",
  "Can-AM": "bcr.Vehicle.TYPE_CAN-AM",
  Casalini: "bcr.Vehicle.TYPE_CASALINI",
  Case: "bcr.Vehicle.TYPE_CASE",
  Caterham: "bcr.Vehicle.TYPE_CATERHAM",
  Caterpillar: "bcr.Vehicle.TYPE_CATERPILLAR",
  CECTEK: "bcr.Vehicle.TYPE_CECTEK",
  CFMOTO: "bcr.Vehicle.TYPE_CFMOTO",
  Challenger: "bcr.Vehicle.TYPE_CHALLENGER",
  "Chang Yang": "bcr.Vehicle.TYPE_CHANG YANG",
  Chatenet: "bcr.Vehicle.TYPE_CHATENET",
  Chevrolet: "bcr.Vehicle.TYPE_CHEVROLET",
  Chinabike: "bcr.Vehicle.TYPE_CHINABIKE",
  Chinaquad: "bcr.Vehicle.TYPE_CHINAQUAD",
  Chrysler: "bcr.Vehicle.TYPE_CHRYSLER",
  CIMC: "bcr.Vehicle.TYPE_CIMC",
  Chunfeng: "bcr.Vehicle.TYPE_CHUNFENG",
  Citroen: "bcr.Vehicle.TYPE_CITROEN",
  Claeys: "bcr.Vehicle.TYPE_CLAEYS",
  Class: "bcr.Vehicle.TYPE_CLASS",
  Commer: "bcr.Vehicle.TYPE_COMMER",
  Corvette: "bcr.Vehicle.TYPE_CORVETTE",
  CPI: "bcr.Vehicle.TYPE_CPI",
  CRS: "bcr.Vehicle.TYPE_CRS",
  Csepel: "bcr.Vehicle.TYPE_CSEPEL",
  Cupra: "bcr.Vehicle.TYPE_CUPRA",
  "D-TEC": "bcr.Vehicle.TYPE_D-TEC",
  Dacia: "bcr.Vehicle.TYPE_DACIA",
  Daelim: "bcr.Vehicle.TYPE_DAELIM",
  Daewoo: "bcr.Vehicle.TYPE_DAEWOO",
  DAF: "bcr.Vehicle.TYPE_DAF",
  Daihatsu: "bcr.Vehicle.TYPE_DAIHATSU",
  Dazon: "bcr.Vehicle.TYPE_DAZON",
  "De Tomaso": "bcr.Vehicle.TYPE_DE_TOMASO",
  Demag: "bcr.Vehicle.TYPE_DEMAG",
  Demmler: "bcr.Vehicle.TYPE_DEMMLER",
  Derbi: "bcr.Vehicle.TYPE_DERBI",
  "Deutz-Fahr": "bcr.Vehicle.TYPE_DEUTZ-FAHR",
  Dinli: "bcr.Vehicle.TYPE_DINLI",
  DKW: "bcr.Vehicle.TYPE_DKW",
  Dodge: "bcr.Vehicle.TYPE_DODGE",
  Donkervoort: "bcr.Vehicle.TYPE_DONKERVOORT",
  Dresel: "bcr.Vehicle.TYPE_DRESEL",
  DS: "bcr.Vehicle.TYPE_DS",
  Ducati: "bcr.Vehicle.TYPE_DUCATI",
  "E-ATV": "bcr.Vehicle.TYPE_E-ATV",
  Epsilon: "bcr.Vehicle.TYPE_EPSILON",
  "E-Racer": "bcr.Vehicle.TYPE_E-RACER",
  Erider: "bcr.Vehicle.TYPE_ERIDER",
  "E-Ton": "bcr.Vehicle.TYPE_E-TON",
  Explorer: "bcr.Vehicle.TYPE_EXPLORER",
  Fantic: "bcr.Vehicle.TYPE_FANTIC",
  Fassi: "bcr.Vehicle.TYPE_FASSI",
  Fastrac: "bcr.Vehicle.TYPE_FASTRAC",
  Faun: "bcr.Vehicle.TYPE_FAUN",
  FAW: "bcr.Vehicle.TYPE_FAW",
  Fendt: "bcr.Vehicle.TYPE_FENDT",
  Ferrari: "bcr.Vehicle.TYPE_FERRARI",
  Fiat: "bcr.Vehicle.TYPE_FIAT",
  Fleetwood: "bcr.Vehicle.TYPE_FLEETWOOD",
  Flexicoil: "bcr.Vehicle.TYPE_FLEXICOIL",
  Fliegl: "bcr.Vehicle.TYPE_FLIEGL",
  FN: "bcr.Vehicle.TYPE_FN",
  Ford: "bcr.Vehicle.TYPE_FORD",
  Foton: "bcr.Vehicle.TYPE_FOTON",
  Freightliner: "bcr.Vehicle.TYPE_FREIGHTLINER",
  FSO: "bcr.Vehicle.TYPE_FSO",
  Fuhrmann: "bcr.Vehicle.TYPE_FUHRMANN",
  Gamax: "bcr.Vehicle.TYPE_GAMAX",
  "Gas Gas": "bcr.Vehicle.TYPE_GAS GAS",
  Geely: "bcr.Vehicle.TYPE_GEELY",
  Generic: "bcr.Vehicle.TYPE_GENERIC",
  "GG-Quad": "bcr.Vehicle.TYPE_GG-QUAD",
  Gilera: "bcr.Vehicle.TYPE_GILERA",
  Ginaf: "bcr.Vehicle.TYPE_GINAF",
  "Glas Hans": "bcr.Vehicle.TYPE_GLAS_HANS",
  Gleaner: "bcr.Vehicle.TYPE_GLEANER",
  GMC: "bcr.Vehicle.TYPE_GMC",
  Goliath: "bcr.Vehicle.TYPE_GOLIATH",
  Gorilla: "bcr.Vehicle.TYPE_GORILLA",
  "Gorki WKE": "bcr.Vehicle.TYPE_GORKI WKE",
  Gottwald: "bcr.Vehicle.TYPE_GOTTWALD",
  Grabhner: "bcr.Vehicle.TYPE_GRABHNER",
  "Gräf & Stift": "bcr.Vehicle.TYPE_GRÄF&STIFT",
  GRC: "bcr.Vehicle.TYPE_GRC",
  Grecav: "bcr.Vehicle.TYPE_GRECAV",
  Grove: "bcr.Vehicle.TYPE_GROVE",
  Gutbrod: "bcr.Vehicle.TYPE_GUTBROD",
  Haili: "bcr.Vehicle.TYPE_HAILI",
  "Hanomag-Henschel": "bcr.Vehicle.TYPE_HANOMAG-HENSCHEL",
  "Harley Davidson": "bcr.Vehicle.TYPE_HARLEY_DAVIDSON",
  "Her Chee": "bcr.Vehicle.TYPE_HER CHEE",
  Herk: "bcr.Vehicle.TYPE_HERK",
  Herkules: "bcr.Vehicle.TYPE_HERKULES",
  Hesston: "bcr.Vehicle.TYPE_HESSTON",
  Hiab: "bcr.Vehicle.TYPE_HIAB",
  Hillman: "bcr.Vehicle.TYPE_HILLMAN",
  Hisun: "bcr.Vehicle.TYPE_HISUN",
  HMF: "bcr.Vehicle.TYPE_HMF",
  HMW: "bcr.Vehicle.TYPE_HMW",
  Holder: "bcr.Vehicle.TYPE_HOLDER",
  Honda: "bcr.Vehicle.TYPE_HONDA",
  Hongdu: "bcr.Vehicle.TYPE_HONGDU",
  Horch: "bcr.Vehicle.TYPE_HORCH",
  Horex: "bcr.Vehicle.TYPE_HOREX",
  Hotzenblitz: "bcr.Vehicle.TYPE_HOTZENBLITZ",
  HSUN: "bcr.Vehicle.TYPE_HSUN",
  Huanan: "bcr.Vehicle.TYPE_HUANAN",
  Huari: "bcr.Vehicle.TYPE_HUARI",
  Huatian: "bcr.Vehicle.TYPE_HUATIAN",
  Huffermann: "bcr.Vehicle.TYPE_HUFFERMANN",
  Humbaur: "bcr.Vehicle.TYPE_HUMBAUR",
  Humber: "bcr.Vehicle.TYPE_HUMBER",
  Hummer: "bcr.Vehicle.TYPE_HUMMER",
  Hurlimann: "bcr.Vehicle.TYPE_HURLIMANN",
  Husaberg: "bcr.Vehicle.TYPE_HUSABERG",
  Husqvarna: "bcr.Vehicle.TYPE_HUSQVARNA",
  Hyosung: "bcr.Vehicle.TYPE_HYOSUNG",
  Hyster: "bcr.Vehicle.TYPE_HYSTER",
  Hyundai: "bcr.Vehicle.TYPE_HYUNDAI",
  IFA: "bcr.Vehicle.TYPE_IFA",
  IHC: "bcr.Vehicle.TYPE_IHC",
  Ikarus: "bcr.Vehicle.TYPE_IKARUS",
  Indian: "bcr.Vehicle.TYPE_INDIAN",
  Infiniti: "bcr.Vehicle.TYPE_INFINITI",
  Innocenti: "bcr.Vehicle.TYPE_INNOCENTI",
  "International Harvester": "bcr.Vehicle.TYPE_INTERNATIONAL_HARVESTER",
  IO: "bcr.Vehicle.TYPE_IO",
  Irisbus: "bcr.Vehicle.TYPE_IRISBUS",
  Irizar: "bcr.Vehicle.TYPE_IRIZAR",
  "Iso WKE": "bcr.Vehicle.TYPE_ISO WKE",
  Isuzu: "bcr.Vehicle.TYPE_ISUZU",
  Italjet: "bcr.Vehicle.TYPE_ITALJET",
  Iveco: "bcr.Vehicle.TYPE_IVECO",
  Jaguar: "bcr.Vehicle.TYPE_JAGUAR",
  Jawa: "bcr.Vehicle.TYPE_JAWA",
  JCB: "bcr.Vehicle.TYPE_JCB",
  JDM: "bcr.Vehicle.TYPE_JDM",
  Jeep: "bcr.Vehicle.TYPE_JEEP",
  Jensen: "bcr.Vehicle.TYPE_JENSEN",
  Jiajue: "bcr.Vehicle.TYPE_JIAJUE",
  Jiashite: "bcr.Vehicle.TYPE_JIASHITE",
  "JI-EE": "bcr.Vehicle.TYPE_JI-EE",
  JMSTAR: "bcr.Vehicle.TYPE_JMSTAR",
  "John Deere": "bcr.Vehicle.TYPE_JOHN DEERE",
  Jonsered: "bcr.Vehicle.TYPE_JONSERED",
  Jonway: "bcr.Vehicle.TYPE_JONWAY",
  Jorskin: "bcr.Vehicle.TYPE_JORSKIN",
  Kaitong: "bcr.Vehicle.TYPE_KAITONG",
  Kamaz: "bcr.Vehicle.TYPE_KAMAZ",
  Karrena: "bcr.Vehicle.TYPE_KARRENA",
  Kassbohrer: "bcr.Vehicle.TYPE_KASSBOHRER",
  Kawasaki: "bcr.Vehicle.TYPE_KAWASAKI",
  Keeway: "bcr.Vehicle.TYPE_KEEWAY",
  Kenworth: "bcr.Vehicle.TYPE_KENWORTH",
  KIA: "bcr.Vehicle.TYPE_KIA",
  Kinroad: "bcr.Vehicle.TYPE_KINROAD",
  Kinglong: "bcr.Vehicle.TYPE_KINGLONG",
  Kirchner: "bcr.Vehicle.TYPE_KIRCHNER",
  KL: "bcr.Vehicle.TYPE_KL",
  Kleinschnittger: "bcr.Vehicle.TYPE_KLEINSCHNITTGER",
  Kobelco: "bcr.Vehicle.TYPE_KOBELCO",
  Kogel: "bcr.Vehicle.TYPE_KOGEL",
  KOMATSU: "bcr.Vehicle.TYPE_KOMATSU",
  Kramer: "bcr.Vehicle.TYPE_KRAMER",
  Kreidler: "bcr.Vehicle.TYPE_KREIDLER",
  Krone: "bcr.Vehicle.TYPE_KRONE",
  Krupp: "bcr.Vehicle.TYPE_KRUPP",
  KTM: "bcr.Vehicle.TYPE_KTM",
  Kubota: "bcr.Vehicle.TYPE_KUBOTA",
  Kutsenits: "bcr.Vehicle.TYPE_KUTSENITS",
  KVN: "bcr.Vehicle.TYPE_KVN",
  Kymco: "bcr.Vehicle.TYPE_KYMCO",
  Lada: "bcr.Vehicle.TYPE_LADA",
  Lamborghini: "bcr.Vehicle.TYPE_LAMBORGHINI",
  Lambretta: "bcr.Vehicle.TYPE_LAMBRETTA",
  Lancia: "bcr.Vehicle.TYPE_LANCIA",
  "Land Rover": "bcr.Vehicle.TYPE_LAND ROVER",
  Landini: "bcr.Vehicle.TYPE_LANDINI",
  Langendorf: "bcr.Vehicle.TYPE_LANGENDORF",
  LanHai: "bcr.Vehicle.TYPE_LANHAI",
  Laverda: "bcr.Vehicle.TYPE_LAVERDA",
  LEM: "bcr.Vehicle.TYPE_LEM",
  Lexus: "bcr.Vehicle.TYPE_LEXUS",
  Liaz: "bcr.Vehicle.TYPE_LIAZ",
  Liebherr: "bcr.Vehicle.TYPE_LIEBHERR",
  Lifeng: "bcr.Vehicle.TYPE_LIFENG",
  Ligier: "bcr.Vehicle.TYPE_LIGIER",
  Lincoln: "bcr.Vehicle.TYPE_LINCOLN",
  Lindner: "bcr.Vehicle.TYPE_LINDNER",
  Lingtian: "bcr.Vehicle.TYPE_LINGTIAN",
  Lloyd: "bcr.Vehicle.TYPE_LLOYD",
  LML: "bcr.Vehicle.TYPE_LML",
  Loncin: "bcr.Vehicle.TYPE_LONCIN",
  "London Taxi": "bcr.Vehicle.TYPE_LONDON_TAXI",
  Lotus: "bcr.Vehicle.TYPE_LOTUS",
  MACK: "bcr.Vehicle.TYPE_MACK",
  Magyar: "bcr.Vehicle.TYPE_MAGYAR",
  "Magirus Deutz": "bcr.Vehicle.TYPE_MAGIRUS_DEUTZ",
  Mahindra: "bcr.Vehicle.TYPE_MAHINDRA",
  Maico: "bcr.Vehicle.TYPE_MAICO",
  Malaguti: "bcr.Vehicle.TYPE_MALAGUTI",
  MAN: "bcr.Vehicle.TYPE_MAN",
  Maruti: "bcr.Vehicle.TYPE_MARUTI",
  Maserati: "bcr.Vehicle.TYPE_MASERATI",
  "Massey Fergusson": "bcr.Vehicle.TYPE_MASSEY_FERGUSSON",
  Matchless: "bcr.Vehicle.TYPE_MATCHLESS",
  Matra: "bcr.Vehicle.TYPE_MATRA",
  Maybach: "bcr.Vehicle.TYPE_MAYBACH",
  Mazda: "bcr.Vehicle.TYPE_MAZDA",
  MBK: "bcr.Vehicle.TYPE_MBK",
  "MB Cimbro": "bcr.Vehicle.TYPE_MB CIMBRO",
  McLaren: "bcr.Vehicle.TYPE_MCLAREN",
  Mega: "bcr.Vehicle.TYPE_MEGA",
  Meiller: "bcr.Vehicle.TYPE_MEILLER",
  "MERCEDES-BENZ": "bcr.Vehicle.TYPE_MERCEDES-BENZ",
  Mercury: "bcr.Vehicle.TYPE_MERCURY",
  Merlo: "bcr.Vehicle.TYPE_MERLO",
  MG: "bcr.Vehicle.TYPE_MG",
  "MH Motorcycles": "bcr.Vehicle.TYPE_MH_MOTORCYCLES",
  Microcar: "bcr.Vehicle.TYPE_MICROCAR",
  Mini: "bcr.Vehicle.TYPE_MINI",
  Michieletto: "bcr.Vehicle.TYPE_MICHIELETTO",
  Mitsubishi: "bcr.Vehicle.TYPE_MITSUBISHI",
  Montesa: "bcr.Vehicle.TYPE_MONTESA",
  Morgan: "bcr.Vehicle.TYPE_MORGAN",
  Morris: "bcr.Vehicle.TYPE_MORRIS",
  Moskvic: "bcr.Vehicle.TYPE_MOSKVIC",
  "Moto Guzzi": "bcr.Vehicle.TYPE_MOTO_GUZZI",
  "Moto Morini": "bcr.Vehicle.TYPE_MOTO_MORINI",
  Multicar: "bcr.Vehicle.TYPE_MULTICAR",
  Multitel: "bcr.Vehicle.TYPE_MULTITEL",
  "MV Agusta": "bcr.Vehicle.TYPE_MV_AGUSTA",
  MZ: "bcr.Vehicle.TYPE_MZ",
  "New Holland": "bcr.Vehicle.TYPE_NEW_HOLLAND",
  Neoplan: "bcr.Vehicle.TYPE_NEOPLAN",
  Niewiadow: "bcr.Vehicle.TYPE_NIEWIADOW",
  Ningbo: "bcr.Vehicle.TYPE_NINGBO",
  Nissan: "bcr.Vehicle.TYPE_NISSAN",
  NOOTEBOOM: "bcr.Vehicle.TYPE_NOOTEBOOM",
  NSU: "bcr.Vehicle.TYPE_NSU",
  Nurdan: "bcr.Vehicle.TYPE_NURDAN",
  "O&K": "bcr.Vehicle.TYPE_O&K",
  OAF: "bcr.Vehicle.TYPE_OAF",
  Oldsmobile: "bcr.Vehicle.TYPE_OLDSMOBILE",
  Oltcit: "bcr.Vehicle.TYPE_OLTCIT",
  OM: "bcr.Vehicle.TYPE_OM",
  Opel: "bcr.Vehicle.TYPE_OPEL",
  Orion: "bcr.Vehicle.TYPE_ORION",
  OTOKAR: "bcr.Vehicle.TYPE_OTOKAR",
  Ossa: "bcr.Vehicle.TYPE_OSSA",
  Oxygen: "bcr.Vehicle.TYPE_OXYGEN",
  Pagani: "bcr.Vehicle.TYPE_PAGANI",
  Palfinger: "bcr.Vehicle.TYPE_PALFINGER",
  Panav: "bcr.Vehicle.TYPE_PANAV",
  Pavic: "bcr.Vehicle.TYPE_PAVIC",
  Pancar: "bcr.Vehicle.TYPE_PANCAR",
  Panhard: "bcr.Vehicle.TYPE_PANHARD",
  Pegaso: "bcr.Vehicle.TYPE_PEGASO",
  Perl: "bcr.Vehicle.TYPE_PERL",
  Peterbilt: "bcr.Vehicle.TYPE_PETERBILT",
  Peugeot: "bcr.Vehicle.TYPE_PEUGEOT",
  PGO: "bcr.Vehicle.TYPE_PGO",
  Piaggio: "bcr.Vehicle.TYPE_PIAGGIO",
  Pirso: "bcr.Vehicle.TYPE_PIRSO",
  Plymouth: "bcr.Vehicle.TYPE_PLYMOUTH",
  PMI: "bcr.Vehicle.TYPE_PMI",
  Polaris: "bcr.Vehicle.TYPE_POLARIS",
  Polini: "bcr.Vehicle.TYPE_POLINI",
  Polonez: "bcr.Vehicle.TYPE_POLONEZ",
  "Polski Fiat": "bcr.Vehicle.TYPE_POLSKI_FIAT",
  Pongratz: "bcr.Vehicle.TYPE_PONGRATZ",
  Pontiac: "bcr.Vehicle.TYPE_PONTIAC",
  Porsche: "bcr.Vehicle.TYPE_PORSCHE",
  PPM: "bcr.Vehicle.TYPE_PPM",
  Praga: "bcr.Vehicle.TYPE_PRAGA",
  Proton: "bcr.Vehicle.TYPE_PROTON",
  Puch: "bcr.Vehicle.TYPE_PUCH",
  Puhringer: "bcr.Vehicle.TYPE_PUHRINGER",
  Putzmeister: "bcr.Vehicle.TYPE_PUTZMEISTER",
  Qingqi: "bcr.Vehicle.TYPE_QINGQI",
  Quantya: "bcr.Vehicle.TYPE_QUANTYA",
  Quattro: "bcr.Vehicle.TYPE_QUATTRO",
  Qvale: "bcr.Vehicle.TYPE_QVALE",
  Raba: "bcr.Vehicle.TYPE_RABA",
  Radauer: "bcr.Vehicle.TYPE_RADAUER",
  Rasant: "bcr.Vehicle.TYPE_RASANT",
  Reform: "bcr.Vehicle.TYPE_REFORM",
  REISCH: "bcr.Vehicle.TYPE_REISCH",
  Reliant: "bcr.Vehicle.TYPE_RELIANT",
  Renault: "bcr.Vehicle.TYPE_RENAULT",
  "Renault V.I.": "bcr.Vehicle.TYPE_RENAULT_V.I.",
  RGP: "bcr.Vehicle.TYPE_RGP",
  Rieju: "bcr.Vehicle.TYPE_RIEJU",
  Riley: "bcr.Vehicle.TYPE_RILEY",
  Robur: "bcr.Vehicle.TYPE_ROBUR",
  RoGator: "bcr.Vehicle.TYPE_ROGATOR",
  "Rolls-Royce": "bcr.Vehicle.TYPE_ROLLS-ROYCE",
  Rover: "bcr.Vehicle.TYPE_ROVER",
  "Royal Enfield": "bcr.Vehicle.TYPE_ROYAL_ENFIELD",
  "S&T": "bcr.Vehicle.TYPE_S&T",
  Saab: "bcr.Vehicle.TYPE_SAAB",
  Sachs: "bcr.Vehicle.TYPE_SACHS",
  SAIC: "bcr.Vehicle.TYPE_SAIC",
  SAME: "bcr.Vehicle.TYPE_SAME",
  Santana: "bcr.Vehicle.TYPE_SANTANA",
  Saris: "bcr.Vehicle.TYPE_SARIS",
  SAS: "bcr.Vehicle.TYPE_SAS",
  Sauber: "bcr.Vehicle.TYPE_SAUBER",
  "Saviem-Renault": "bcr.Vehicle.TYPE_SAVIEM-RENAULT",
  Scania: "bcr.Vehicle.TYPE_SCANIA",
  Schmitz: "bcr.Vehicle.TYPE_SCHMITZ",
  "Schmitz Cargobull": "bcr.Vehicle.TYPE_SCHMITZ CARGOBULL",
  Schwarzmuller: "bcr.Vehicle.TYPE_SCHWARZMULLER",
  Scorpa: "bcr.Vehicle.TYPE_SCORPA",
  "Sea-Doo": "bcr.Vehicle.TYPE_SEA-DOO",
  Seat: "bcr.Vehicle.TYPE_SEAT",
  "Self-construction/Special_construction":
    "bcr.Vehicle.TYPE_SELF-CONSTRUCTION/SPECIAL_CONSTRUCTION",
  Selinger: "bcr.Vehicle.TYPE_SELINGER",
  Setra: "bcr.Vehicle.TYPE_SETRA",
  SFM: "bcr.Vehicle.TYPE_SFM",
  "Shang Wie": "bcr.Vehicle.TYPE_SHANG WIE",
  Shenke: "bcr.Vehicle.TYPE_SHENKE",
  Sherco: "bcr.Vehicle.TYPE_SHERCO",
  Shineray: "bcr.Vehicle.TYPE_SHINERAY",
  Shiwei: "bcr.Vehicle.TYPE_SHIWEI",
  Simca: "bcr.Vehicle.TYPE_SIMCA",
  Sinski: "bcr.Vehicle.TYPE_SINSKI",
  Sisu: "bcr.Vehicle.TYPE_SISU",
  SKS: "bcr.Vehicle.TYPE_SKS",
  Skoda: "bcr.Vehicle.TYPE_SKODA",
  Skyteam: "bcr.Vehicle.TYPE_SKYTEAM",
  Smart: "bcr.Vehicle.TYPE_SMART",
  SMC: "bcr.Vehicle.TYPE_SMC",
  Sonik: "bcr.Vehicle.TYPE_SONIK",
  SpraCoupe: "bcr.Vehicle.TYPE_SPRACOUPE",
  Ssangyong: "bcr.Vehicle.TYPE_SSANGYONG",
  Standard: "bcr.Vehicle.TYPE_STANDARD",
  Star: "bcr.Vehicle.TYPE_STAR",
  "Start Lab": "bcr.Vehicle.TYPE_START_LAB",
  STAS: "bcr.Vehicle.TYPE_STAS",
  Stema: "bcr.Vehicle.TYPE_STEMA",
  "Stetzl & Söhne": "bcr.Vehicle.TYPE_STETZL&SÖHNE",
  Steyr: "bcr.Vehicle.TYPE_STEYR",
  Stoewer: "bcr.Vehicle.TYPE_STOEWER",
  Studebaker: "bcr.Vehicle.TYPE_STUDEBAKER",
  Subaru: "bcr.Vehicle.TYPE_SUBARU",
  Sunflower: "bcr.Vehicle.TYPE_SUNFLOWER",
  Sunliving: "bcr.Vehicle.TYPE_SUNLIVING",
  Suzuki: "bcr.Vehicle.TYPE_SUZUKI",
  SYM: "bcr.Vehicle.TYPE_SYM",
  Talbot: "bcr.Vehicle.TYPE_TALBOT",
  Tata: "bcr.Vehicle.TYPE_TATA",
  Tatra: "bcr.Vehicle.TYPE_TATRA",
  Tauris: "bcr.Vehicle.TYPE_TAURIS",
  Tavria: "bcr.Vehicle.TYPE_TAVRIA",
  TBG: "bcr.Vehicle.TYPE_TBG",
  "Tempo WKE": "bcr.Vehicle.TYPE_TEMPO_WKE",
  Temsa: "bcr.Vehicle.TYPE_TEMSA",
  TerraGator: "bcr.Vehicle.TYPE_TERRAGATOR",
  Tesla: "bcr.Vehicle.TYPE_TESLA",
  "Think City": "bcr.Vehicle.TYPE_THINK CITY",
  Thumpstar: "bcr.Vehicle.TYPE_THUMPSTAR",
  Tianma: "bcr.Vehicle.TYPE_TIANMA",
  TM: "bcr.Vehicle.TYPE_TM",
  Tomos: "bcr.Vehicle.TYPE_TOMOS",
  Toyota: "bcr.Vehicle.TYPE_TOYOTA",
  Trabant: "bcr.Vehicle.TYPE_TRABANT",
  Trailor: "bcr.Vehicle.TYPE_TRAILOR",
  Triumph: "bcr.Vehicle.TYPE_TRIUMPH",
  TVR: "bcr.Vehicle.TYPE_TVR",
  Unic: "bcr.Vehicle.TYPE_UNIC",
  Ural: "bcr.Vehicle.TYPE_URAL",
  Valtra: "bcr.Vehicle.TYPE_VALTRA",
  Vauxhall: "bcr.Vehicle.TYPE_VAUXHALL",
  Vectrix: "bcr.Vehicle.TYPE_VECTRIX",
  Vespa: "bcr.Vehicle.TYPE_VESPA",
  Victory: "bcr.Vehicle.TYPE_VICTORY",
  Volga: "bcr.Vehicle.TYPE_VOLGA",
  Volvo: "bcr.Vehicle.TYPE_VOLVO",
  VOR: "bcr.Vehicle.TYPE_VOR",
  Voxan: "bcr.Vehicle.TYPE_VOXAN",
  VW: "bcr.Vehicle.TYPE_VW",
  Wanderer: "bcr.Vehicle.TYPE_WANDERER",
  Wartburg: "bcr.Vehicle.TYPE_WARTBURG",
  Wellmar: "bcr.Vehicle.TYPE_WELLMAR",
  Westfalia: "bcr.Vehicle.TYPE_WESTFALIA",
  WH: "bcr.Vehicle.TYPE_WH",
  White: "bcr.Vehicle.TYPE_WHITE",
  Wielton: "bcr.Vehicle.TYPE_WIELTON",
  Wiesmann: "bcr.Vehicle.TYPE_WIESMANN",
  Wolseley: "bcr.Vehicle.TYPE_WOLSELEY",
  Xingyue: "bcr.Vehicle.TYPE_XINGYUE",
  Xispa: "bcr.Vehicle.TYPE_XISPA",
  Xmotos: "bcr.Vehicle.TYPE_XMOTOS",
  Yamaha: "bcr.Vehicle.TYPE_YAMAHA",
  Yiben: "bcr.Vehicle.TYPE_YIBEN",
  Yugo: "bcr.Vehicle.TYPE_YUGO",
  Zagato: "bcr.Vehicle.TYPE_ZAGATO",
  Zaporožec: "bcr.Vehicle.TYPE_ZAPOROŽEC",
  Zaslaw: "bcr.Vehicle.TYPE_ZASLAW",
  Zastava: "bcr.Vehicle.TYPE_ZASTAVA",
  Zaz: "bcr.Vehicle.TYPE_ZAZ",
  Zetor: "bcr.Vehicle.TYPE_ZETOR",
  Zhongneng: "bcr.Vehicle.TYPE_ZHONGNENG",
  ZIL: "bcr.Vehicle.TYPE_ZIL",
  Zongshen: "bcr.Vehicle.TYPE_ZONGSHEN",
  Altele: "bcr.Vehicle.TYPE_ALTELE",
};

// Counties
export const inp_lessee_county = {
  "-": "bcr.County_-",
  ALBA: "bcr.County_ALBA",
  ARGES: "bcr.County_ARGES",
  ARAD: "bcr.County_ARAD",
  BUCURESTI: "bcr.County_BUCURESTI",
  BACAU: "bcr.County_BACAU",
  BIHOR: "bcr.County_BIHOR",
  "BISTRITA-NASAUD": "bcr.County_BISTRITA-NASAUD",
  BRAILA: "bcr.County_BRAILA",
  BOTOSANI: "bcr.County_BOTOSANI",
  BRASOV: "bcr.County_BRASOV",
  BUZAU: "bcr.County_BUZAU",
  CLUJ: "bcr.County_CLUJ",
  CALARASI: "bcr.County_CALARASI",
  "CARAS-SEVERIN": "bcr.County_CARAS-SEVERIN",
  CONSTANTA: "bcr.County_CONSTANTA",
  COVASNA: "bcr.County_COVASNA",
  DAMBOVITA: "bcr.County_DAMBOVITA",
  DOLJ: "bcr.County_DOLJ",
  GORJ: "bcr.County_GORJ",
  GALATI: "bcr.County_GALATI",
  GIURGIU: "bcr.County_GIURGIU",
  HUNEDOARA: "bcr.County_HUNEDOARA",
  HARGHITA: "bcr.County_HARGHITA",
  ILFOV: "bcr.County_ILFOV",
  IALOMITA: "bcr.County_IALOMITA",
  IASI: "bcr.County_IASI",
  MEHEDINTI: "bcr.County_MEHEDINTI",
  MARAMURES: "bcr.County_MARAMURES",
  MURES: "bcr.County_MURES",
  NEAMT: "bcr.County_NEAMT",
  OLT: "bcr.County_OLT",
  PRAHOVA: "bcr.County_PRAHOVA",
  SIBIU: "bcr.County_SIBIU",
  SALAJ: "bcr.County_SALAJ",
  "SATU-MARE": "bcr.County_SATU-MARE",
  SUCEAVA: "bcr.County_SUCEAVA",
  TULCEA: "bcr.County_TULCEA",
  TIMIS: "bcr.County_TIMIS",
  TELEORMAN: "bcr.County_TELEORMAN",
  VALCEA: "bcr.County_VALCEA",
  VRANCEA: "bcr.County_VRANCEA",
  VASLUI: "bcr.County_VASLUI",
};

export const inp_policy_payment = {
  anuala: "bcr.Vehicle.PAYMENT_METHOD_ANNUALLY",
  semestriala: "bcr.Vehicle.PAYMENT_METHOD_SEMESTER",
  trimestriala: "bcr.Vehicle.PAYMENT_METHOD_QUARTERLY",
  lunara: "bcr.Vehicle.PAYMENT_METHOD_MONTHLY",
};

export const insurerCasco = {
  omniasig: "bcr.Insurer_OMNIASIG",
  asirom: "bcr.Insurer_ASIROM",
  allianz: "bcr.Insurer_ALLIANZ",
  uniqa: "bcr.Insurer_UNIQA",
  generali: "bcr.Insurer_GENERALI",
  groupama: "bcr.Insurer_GROUPAMA",
};

export const invertedInsurerCasco = {
  "bcr.Insurer_OMNIASIG": "omniasig",
  "bcr.Insurer_ASIROM": "asirom",
  "bcr.Insurer_ALLIANZ": "allianz",
  "bcr.Insurer_UNIQA": "uniqa",
  "bcr.Insurer_GENERALI": "generali",
  "bcr.Insurer_GROUPAMA": "groupama",
};

export const inp_asistentarutiera = {
  SILVER: "bcr.Road_assistance.SILVER",
  GOLD: "bcr.Road_assistance.GOLD",
  VIP: "bcr.Road_assistance.VIP",
};

export const inp_vehicle_fuel = {
  benzina: "bcr.Vehicle.FUEL_BENZINE",
  motorina: "bcr.Vehicle.FUEL_DIESEL",
  electric: "bcr.Vehicle.FUEL_ELECTRIC",
  "benzina si gpl": "bcr.Vehicle.FUEL_PETROL_AND_LPG",
  "benzina si alcool": "bcr.Vehicle.FUEL_PETROL_AND_ALCOHOL",
  "hybrid benzina": "bcr.Vehicle.FUEL_HYBRID_PETROL",
  "hybrid motorina": "bcr.Vehicle.FUEL_HYBRID_DIESEL",
  fara: "bcr.Vehicle.FUEL_NONE",
  altul: "bcr.Vehicle.FUEL_OTHER",
};

export const inp_vehicle_fleet = {
  "parc auto intre 0-4": "bcr.Vehicle.CAR_FLEET_BETWEEN_0-4",
  "parc auto intre 5-20": "bcr.Vehicle.CAR_FLEET_BETWEEN_5-20",
};

export const inp_contract_type = {
  "Contract de leasing financiar":
    "bcr.Vehicle.CONTRACT_TYPE_FINANCIAL_LEASING_AGREEMENT",
  "Contract de leasing operational":
    "bcr.Vehicle.CONTRACT_TYPE_FINANCIAL_LEASING_OPERATIONAL",
  "Contract de credit": "bcr.Vehicle.CONTRACT_TYPE_CREDIT_CONTRACT",
};

export const inp_vehicle_carusage = {
  Normal: "bcr.Vehicle.USE_NORMAL",
  "Drive test": "bcr.Vehicle.USE_DRIVE_TEST",
  Transport: "bcr.Vehicle.USE_TRANSPORT",
  "Paza, protectie, interventie":
    "bcr.Vehicle.USE_GUARD_PROTECTION_INTERVENTION",
  "Rent-a-car": "bcr.Vehicle.USE_RENT-A-CAR",
  "Scoala de soferi": "bcr.Vehicle.USE_DRIVING_SCHOOL",
  Taximetrie: "bcr.Vehicle.USE_TAXI",
  Distributie: "bcr.Vehicle.USE_DISTRIBUTION",
  "Transport marfuri in regim ADR":
    "bcr.Vehicle.USE_TRANSPORT_OF_GOODS_UNDER_ADR_REGIME",
  Curierat: "bcr.Vehicle.USE_COURIER",
  "Institutie publica": "bcr.Vehicle.USE_PUBLIC_INSTITUTION",
  Constructii: "bcr.Vehicle.USE_CONSTRUCTIONS",
  "Transport produse farmaceutice":
    "bcr.Vehicle.USE_TRANSPORT_OF_PHARMACEUTICAL_PRODUCTS",
  "Transport persoane": "bcr.Vehicle.USE_PASSENGER_TRANSPORTATION",
  "Masina de serviciu": "bcr.Vehicle.USE_SERVICE_CAR",
};

export const inp_policy_currency = {
  EUR: "bcr.Vehicle.CONTRACT_CURRENCY_EUR",
  RON: "bcr.Vehicle.CONTRACT_CURRENCY_RON",
};

export const inp_vehicle_registration = {
  inmatriculat: "bcr.Registration_type_MATRICULATED",
  inregistrat: "bcr.Registration_type_REGISTERED",
  "in vederea inregistrarii": "bcr.Registration_type_IN_ORDER_TO_REGISTER",
  "in vederea inmatricularii": "bcr.Registration_type_IN_ORDER_TO_MATRICULATE",
};

// "bcr.Registration_type_MATRICULATED": "inmatriculat",
// "bcr.Registration_type_REGISTERED": "inregistrat",
// "bcr.Registration_type_IN_ORDER_TO_REGISTER": "in vederea inregistrarii",
// "bcr.Registration_type_IN_ORDER_TO_MATRICULATE": "in vederea inmatricularii",

// Inverzne mape

export const subcat_mappings = [
  // AUTOTOURISM
  {
    subcats: [
      "bcr.SubCategory.AUTOTURISM",
      "bcr.SubCategory.AUTOMOBIL_MIXED",
      "bcr.SubCategory.OFF-ROAD",
    ],
    cat: "bcr.Category.TOURISM",
  },
  // COMERCIAL
  {
    subcats: [
      "bcr.SubCategory.DUMPER",
      "bcr.SubCategory.MIXER_TRUCK",
      "bcr.SubCategory.LORRY",
      "bcr.SubCategory.TANK",
      "bcr.SubCategory.AUTOEXCAVATOR",
      "bcr.SubCategory.MOTORVAN",
      "bcr.SubCategory.SELF_GRADER",
      "bcr.SubCategory.LOADING_MEMBER",
      "bcr.SubCategory.CRANE",
      "bcr.SubCategory.TRUCK",
      "bcr.SubCategory.SELF-SPECIALIZED",
      "bcr.SubCategory.VAN",
      "bcr.SubCategory.TIPPER",
      "bcr.SubCategory.BACKHOE",
      "bcr.SubCategory.TRUCK_CAMION",
      "bcr.SubCategory.FURGON",
    ],
    cat: "bcr.Category.COMERCIAL",
  },

  // TRANSPORTING
  {
    subcats: [
      "bcr.SubCategory.BUS",
      "bcr.SubCategory.ARTICULATED_BUS",
      "bcr.SubCategory.BUS_SPECIAL",
      "bcr.SubCategory.AUTOCAR",
      "bcr.SubCategory.MINIBUS",
      "bcr.SubCategory.TRAM",
      "bcr.SubCategory.TROLLEYBUS",
    ],
    cat: "bcr.Category.TRANSPORTING",
  },

  // FARM
  {
    subcats: [
      "bcr.SubCategory.AUTOTRACTOR",
      "bcr.SubCategory.CAP_TRACTOR",
      "bcr.SubCategory.AUTO_TRACTOR_WITH_ITS",
    ],
    cat: "bcr.Category.FARM",
  },

  // CAMP
  { subcat: "bcr.SubCategory.CAMP", cat: "bcr.Category.CAMP" },

  // MOTORCYCLE
  {
    subcats: [
      "bcr.SubCategory.ATV",
      "bcr.SubCategory.MOPED",
      "bcr.SubCategory.MOTORCYCLE",
      "bcr.SubCategory.MOTOR_SCOOTER",
    ],
    cat: "bcr.Category.MOTORCYCLE",
  },

  // TRAILERS_SEMI-TRAILERS
  {
    subcats: [
      "bcr.SubCategory.CAMPER_VAN",
      "bcr.SubCategory.SEMI_TOW",
      "bcr.SubCategory.TOW",
    ],
    cat: "bcr.Category.TRAILERS_SEMI-TRAILERS",
  },

  // TRAILERS_SEMI-TRAILERS_CISTERN
  {
    subcats: [
      "bcr.SubCategory.CAMPER_VAN",
      "bcr.SubCategory.SEMI_TOW",
      "bcr.SubCategory.TOW",
    ],
    cat: "bcr.Category.TRAILERS_SEMI-TRAILERS_CISTERN",
  },

  // MIXED_VEHICLES
  {
    subcats: [
      "bcr.SubCategory.VAN_MIXED",
      "bcr.SubCategory.TANK_MIXED",
      "bcr.SubCategory.MACHINERY",
    ],
    cat: "bcr.Category.MIXED_VEHICLES",
  },

  // AGRICULTURAL
  { subcat: "bcr.SubCategory.MACHINERY", cat: "bcr.Category.AGRICULTURAL" },
  // AGRICULTURAL_NON-PROPELLED
  {
    subcat: "bcr.SubCategory.MACHINERY",
    cat: "bcr.Category.AGRICULTURAL_NON-PROPELLED",
  },

  // TRACTOR_ROAD
  {
    subcats: [
      "bcr.SubCategory.ROAD_TRACTOR",
      "bcr.SubCategory.AGRICULTURE_TRACTOR",
    ],
    cat: "bcr.Category.TRACTOR_ROAD",
  },

  // REFIGERATED_TRAILERS
  {
    subcats: [
      "bcr.SubCategory.CAMPER_VAN",
      "bcr.SubCategory.SEMI_TOW",
      "bcr.SubCategory.TOW",
    ],
    cat: "bcr.Category.REFIGERATED_TRAILERS",
  },
];

export const inverted_labels = {
  "Data expirare ITP": "bcr.Expiration_date_ITP.label",
  "BCR ZIP file for Asirom is now being prepared, you will receive an email after they are ready.":
    "bcr.asiromMessage.label",
  "BCR Excel files are now being prepared, you will receive an email after they are ready.":
    "bcr.bcrMessage.label",
  "Your excel with renewal information is now being processed, you will receive an email informing you about the result.":
    "bcr.reinnoiri.label",
  "Your excel with mtpl offer information is now being processed, you will receive an email informing you about the result.":
    "bcr.reinnoiriNonGreco.label",
  "BCR Leasing (Reînnoiri non_GrECo)": "bcr.BCR_Leasing_Renewals_non_GrECo",
  "BCR Leasing (Reînnoiri)": "bcr.BCR_Leasing_Renewals",
  "Vă rugăm să încărcați fișierul excel cu datele de reînnoire":
    "bcr.Please_upload_excel_file",
  "Atentie! Oferta trebuie semnata!": "bcr.Warning_Offer_must_be_signed",
  "BCR Leasing (Exportați în Excel pentru BCR)":
    "bcr.BCR_Leasing_Export_to_Excel",
  "BCR Leasing (Exportați în Excel pentru Asirom)":
    "bcr.BCR_Leasing_Export_to_Excel_Asirom",
  "Pachet asistenta rutiera": "bcr.Road_assistance_package.label",
  "Prima anuala": "bcr.Annual_Premium.label",
  "Oferta noua": "bcr.NewOffer.label",
  "Oferta noua RCA": "bcr.NewOfferRCA.label",
  "Exportați pentru Asirom": "bcr.ExportAsirom.label",
  "Exportați pentru BCR": "bcr.ExportBCR.label",
  Reînnoiri: "bcr.Renewal.label",
  "Nume utilizator": "bcr.Username.label",
  "Reînnoiri non_GrECo": "bcr.Renewal_Non_Greco.label",
  "Data ofertei": "bcr.offers.offerRequestedAt.label",
  "Creat de": "bcr.offers.offerRequestedByName.label",
  Reînnoit: "bcr.offers.isRenewed.label",
  "Tip ctr": "bcr.offers.inp_lessee_type.label",
  Marca: "bcr.offers.inp_vehicle_brand.label",
  Model: "bcr.offers.inp_vehicle_model.label",
  "C.U.I": "bcr.offers.inp_lessee_uid.label",
  "C.N.P": "bcr.offers.inp_lessee_compreg.label",
  "Serie sasiu": "bcr.offers.inp_vehicle_vin.label",
  Brand: "bcr.offers.vehicleBrand.label",
  "Nr. ctrl": "bcr.offers.leasingctrno.label",
  "Valab.": "bcr.offers.validFrom.label",
  "Documente emise": "bcr.offers.document.label",
  "Apasati Enter pentru a aplica criteriile de cautare.":
    "bcr.sectionText1.label",
  "1) Pentru a cauta 2 termeni oriunde in coloane completati folosind un spatiu intre cei doi (e.g. Audi Polita)  ":
    "bcr.sectionText2.label",
  "2) Pentru a cauta un termen oriunde in coloane dar si un al doilea, separati-i folosind spatiu si folositi cratima inaintea celui de-al doilea (e.g. Audi -Polita)  ":
    "bcr.sectionText3.label",
  "3) Pentru a cauta 2 termeni apartinand aceleasi expresii folositi ghilimelele (e.g. 'Oferta CASCO')  ":
    "bcr.sectionText4.label",
  "4) Pentru a cauta un termen ce contine anumite caractere folositi simbolul * inainte sau dupa (e.g. WDB123*)  ":
    "bcr.sectionText5.label",
  Inapoi: "bcr.Back.label",
  Tabs: "bcr.offerTabs.label",
  Panel: "bcr.Panel.label",
  "Introducere date": "bcr.dataEntry.label",
  "Tip persoana": "bcr.PersonType.label",
  "Stare autovehicul": "bcr.Vehicle_condition.label",
  "Valoare ctr. leasing (+TVA)": "bcr.Value_for_leasing_(+VAT).label",
  "An fabricatie": "bcr.Manufacturing_year.label",
  "Data contract leasing": "bcr.Data_contract_leasing.label",
  "An prima inmatriculare": "bcr.An_prima_inmatriculare.label",
  Categoria: "bcr.Category.label",
  Subcategorie: "bcr.SubCategory.label",
  "Masa max. autorizata (kg)": "bcr.Max-mass_authorized-(kg).label",
  "Nr. locuri": "bcr.Number_of_seats.label",
  "Utilizare autovehicul": "bcr.Vehicle_use.label",
  "Metoda de plata": "bcr.Payment_method.label",
  "Perioada leasing (luni)": "bcr.Leasing_period_(months).label",
  "Parc auto": "bcr.Fleet.label",
  "Valuta contract": "bcr.Contract_currency.label",
  "Tip contract": "bcr.Contract_type.label",
  Combustibil: "bcr.Combustible.label",
  "Clauze CASCO": "bcr.CASCO_clauses.label",
  "Anvelope / Jante": "bcr.Tires_Rims.label",
  "Instalatie de lucru": "bcr.Work_installation.label",
  "Extindere teritoriala": "bcr.Territorial_expansion.label",
  "Avarii produse de marfa transp.": "bcr.Damage_caused_by_transp.label",
  "Greve, revolte, tulburari civile":
    "bcr.Strikes_riots_civil_disturbances.label",
  "Incarcare/descarcare a marfurilor": "bcr.Loading/unloading_of_goods.label",
  "Extindere (ERS)": "bcr.Extension_(ERS).label",
  Transportatori: "bcr.Carriers.label",
  "Asistenta rutiera": "bcr.Road_assistance.label",
  "Reducere (%)": "bcr.Discount_(%).label",
  "Majorare (%)": "bcr.Increase_(%).label",
  "Asigurator (Reducere/Majorare)": "bcr.Insurer_(Reduction/Increase).label",
  "Info CASCO": "bcr.CASCO_info.label",
  Utilizator: "bcr.User.label",
  Nume: "bcr.Last_name.label",
  Prenume: "bcr.First_name.label",
  "Numele companiei": "bcr.Company_Name.label",
  "Cod CAEN": "bcr.CAEN_code.label",
  Strada: "bcr.The_street.label",
  Numar: "bcr.Number.label",
  Oras: "bcr.Town.label",
  Judet: "bcr.County.label",
  Bloc: "bcr.Block.label",
  Scara: "bcr.Scale.label",
  Apartament: "bcr.Apartment.label",
  Etaj: "bcr.Floor.label",
  Mobil: "bcr.Mobile.label",
  "E-mail": "bcr.E-mail.label",
  "Vehicul asigurat": "bcr.The_insured_vehicle.label",
  "Putere (kW)": "bcr.Power_(kW).label",
  Cilindree: "bcr.Displacement.label",
  RCA: "bcr.RCA.label",
  "Informatii suplimentare RCA": "bcr.RCA_additional_information.label",
  "Reînnoit?": "bcr.Renewed.label",
  "Data inceput": "bcr.Start_date.label",
  "Tipul societatii": "bcr.The_type_of_company.label",
  "Carte CIV (A000000)": "bcr.CIV_book_(A000000).label",
  "Tip inmatriculare": "bcr.Registration_type.label",
  "Nr. inmatriculare": "bcr.No._matriculation.label",
  "Data permisului (pentru PF*)": "bcr.License_date_(for_PF*).label",
  "Conducător C.N.P": "bcr.Leader_C.N.P.label",
  "Conducător Nume": "bcr.Driver_Last_name.label",
  "Conducător Prenume": "bcr.Driver_First_name.label",
  "Conducător Seria": "bcr.Driver_Series.label",
  "Conducător Numar": "bcr.Driver_Number.label",
  "Conducător Telefon": "bcr.Driver_Phone.label",
  "Comision Broker": "bcr.Broker_commission.label",
  "Info CASCO si RCA": "bcr.Info_CASCO_and_RCA.label",
  "Obtineti Cotatiile": "bcr.Get_Quotes.label",
  "Oferta CASCO": "bcr.CASCO_offer.label",
  HTML: "bcr.HTML.label",
  "Field Set": "bcr.Field_Set.label",
  "Omniasig prima": "bcr.Omniasig_first.label",
  "Omniasig prima promotionala": "bcr.Omniasig_first_promotional.label",
  "Asirom prima": "bcr.Asirom_first.label",
  "Allianz prima": "bcr.Allianz_first.label",
  "Uniqa prima": "bcr.Uniqa_first.label",
  "Generali prima": "bcr.Generali_first.label",
  "Groupama prima": "bcr.Groupama_first.label",
  "Curs valutar EUR": "bcr.EUR_exchange_rate.label",
  "Data curs valutar EUR": "bcr.EUR_exchange_rate_date.label",
  "Oferta RCA (RON)": "bcr.RCA_offer_(RON).label",
  "Omniasig prima 6 luni": "bcr.Omniasig_first_6_months.label",
  "Omniasig prima 6 luni dd": "bcr.Omniasig_first_6_months.label_dd",
  "Omniasig prima 12 luni": "bcr.Omniasig_first_12_months.label",
  "Omniasig prima 12 luni dd": "bcr.Omniasig_first_12_months.label_dd",
  "Asirom prima 6 luni": "bcr.Asirom_first_6_months.label",
  "Asirom prima 6 luni dd": "bcr.Asirom_first_6_months.label_dd",
  "Asirom prima 12 luni": "bcr.Asirom_first_12_months.label",
  "Asiromprima 12 luni dd": "bcr.Asirom_first_12_months.label_dd",
  "Allianz prima 6 luni": "bcr.Allianz_first_6_months.label",
  "Allianz prima 6 luni dd": "bcr.Allianz_first_6_months.label_dd",
  "Allianz prima 12 luni": "bcr.Allianz_first_12_months.label",
  "Allianz prima 12 luni dd": "bcr.Allianz_first_12_months.label_dd",
  "Generali prima 6 luni": "bcr.Generali_first_6_months.label",
  "Generali prima 6 luni dd": "bcr.Generali_first_6_months.label_dd",
  "Generali prima 12 luni": "bcr.Generali_first_12_months.label",
  "Generali prima 12 luni dd": "bcr.Generali_first_12_months.label_dd",
  "Groupama prima 6 luni": "bcr.Groupama_first_6_months.label",
  "Groupama prima 6 luni dd": "bcr.Groupama_first_6_months.label_dd",
  "Groupama prima 12 luni": "bcr.Groupama_first_12_months.label",
  "Groupama prima 12 luni dd": "bcr.Groupama_first_12_months.label_dd",
  "Grawe prima 6 luni": "bcr.Grawe_first_6_months.label",
  "Grawe prima 6 luni dd": "bcr.Grawe_first_6_months.label_dd",
  "Grawe prima 12 luni": "bcr.Grawe_first_12_months.label",
  "Grawe prima 12 luni dd": "bcr.Grawe_first_12_months.label_dd",
  "Tariful de referinta calculat": "bcr.The_calculated_reference_rate.label",
  "Info BAAR": "bcr.Info_BAAR.label",
  "Arata erori": "bcr.Arata_erori.label",
  "Ascunde erori": "bcr.Ascunde_erori.label",
  "Oferta nr.": "bcr.Offer_no.label",
  "Oferta ceruta la:": "bcr.Offer_requested_at.label",
  "Oferta ceruta de:": "bcr.Offer_requested_by.label",
  Oferta: "bcr.Offer.label",
  "Oferta Gap": "bcr.Gap_offer.label",
  "Actualizare oferta": "bcr.Update_the_offer.label",
  "Bifati daca doriti invalidarea ofertei":
    "bcr.Tick_if_you_want_to_invalidate_the_offer.label",
  "Invalidare oferta": "bcr.Invalidation_of_the_offer.label",
  Asigurator: "bcr.Insurer.label",
  "Decont si Polita CASCO": "bcr.Settlement_and_CASCO_Policy.label",
  "Prima promotionala": "bcr.First_promotion.label",
  "Numar contract leasing": "bcr.Leasing_contract_number.label",
  "Generați decont": "bcr.Generate_bill.label",
  "Decont nr.": "bcr.Statement_no.label",
  Prima: "bcr.Received.label",
  "Rata nr. 1": "bcr.Rate_no._1.label",
  "Decont emis la:": "bcr.Statement_issued_to.label",
  "Decont emis de:": "bcr.Statement issued by.label",
  Decont: "bcr.Expense_account.label",
  "Data inceput asigurare": "bcr.Insurance_start_date.label",
  Livrare: "bcr.Delivery.label",
  "A fost receptionata oferta semnata?":
    "bcr.Was_the_offer_signed_received?.label",
  "Emiteti polita": "bcr.Policy_issue.label",
  "Numar polita": "bcr.Policy_number.label",
  "Polita emisa la": "bcr.Policy_issued_at.label",
  "Polita emisa de": "bcr.Policy_issued_by.label",
  Polita: "bcr.Policy.label",
  "Emiteti polita total": "bcr.Total_policy.label",
  "Numar polita casco total": "bcr.Total_policy_number.label",
  "Prima casco total": "bcr.The_first_casco_total.label",
  "Polita casco total emisa la": "bcr.Total_casco_policy_issued_at.label",
  "Polita casco total emisa de": "bcr.Total_casco_policy_issued_by.label",
  "Polita casco total": "bcr.Total_casco_policy.label",
  "Se doreste emitere polita cu acoperire la dauna partiala?":
    "bcr.Do_you_want_to_issue_a_policy_with_partial_damage_coverage?.label",
  "Emiteti polita partial": "bcr.Issue_partial_policy.label",
  "Numar polita casco partial": "bcr.Partial_policy.label",
  "Prima casco partial": "bcr.The_first_casco_partial.label",
  "Polita casco partial emisa la": "bcr.Partial_casco_policy_issued_to.label",
  "Polita casco partial emisa de": "bcr.Partial_casco_policy_issued_by.label",
  "Polita casco partial": "bcr.Partial_casco_policy.label",
  "Decont si Polita RCA": "bcr.Settlement_and_RCA_Policy.label",
  "Perioada de asigurare (luni)": "bcr.Period_of_insurance_(months).label",
  "Decontare directa": "bcr.Direct_settlement.label",
  "Data platii": "bcr.Date_of_payment.label",
  "Serie polita": "bcr.Policy_series.label",
  "Oferta CASCO si RCA": "bcr.Offer_CASCO_si_RCA.label",
  "Vă rugăm să selectați perioada de emitere a polițelor": "bcr.Header.label",
  Start: "bcr.Start_Date.label",
  Sfârșit: "bcr.End_Date.label",
  Export: "bcr.Export.label",
  "Fișierele dvs. ZIP cu informații de export sunt în curs de procesare, veți primi un e-mail care vă va informa cu privire la rezultat.":
    "bcr.Alert.label",
  "Ofertă nevalidă": "bcr.offers.contextMenu.invalidate",
  "Repetă oferta": "bcr.offers.contextMenu.repeat",
  Standard: "bcr.Standard.label",
  Partial: "bcr.Partial.label",
  Total: "bcr.Total.label",
  "1 lună": "bcr.1_month.label",
  "6 luni": "bcr.6_months.label",
  "12 luni": "bcr.12_months.label",
  "Fara DD": "bcr.No_DD.label",
  "Cu DD": "bcr.With_DD.label",
};

const last10Years = () => {
  const years = Array.from({ length: 11 }, (v, k) => k + 1).map(
    (v) => new Date().getFullYear() + 1 - v
  );
  const retVal = years.map((year) => {
    return { value: year + "", label: year + "" };
  });
  return retVal;
};

const last100Years = () => {
  const years = Array.from(
    { length: new Date().getFullYear() - 1899 },
    (v, k) => k + 1
  ).map((v) => new Date().getFullYear() + 1 - v);
  const retVal = years.map((year) => {
    return { value: year + "", label: year + "" };
  });
  return retVal;
};

// const last10Years_flat = () => {
//   let retVal = {};
//   last10Years().forEach((e) => {
//     retVal[e] = e;
//   });
//   return retVal;
// };

const inp_vehicle_construction = last10Years();
const inp_vehicle_firstreg = last10Years();

const inp_vehicle_construction100 = last100Years();
const inp_vehicle_firstreg100 = last100Years();

const inp_vehicle_construction_flat = inp_vehicle_construction.reduce(
  (acc, { label, value }) => {
    acc[Number(value)] = Number(label);
    return acc;
  },
  {}
);
const inp_vehicle_firstreg_flat = inp_vehicle_firstreg.reduce(
  (acc, { label, value }) => {
    acc[Number(value)] = Number(label);
    return acc;
  },
  {}
);

const inp_vehicle_construction_flat100 = inp_vehicle_construction100.reduce(
  (acc, { label, value }) => {
    acc[Number(value)] = Number(label);
    return acc;
  },
  {}
);
const inp_vehicle_firstreg_flat100 = inp_vehicle_firstreg100.reduce(
  (acc, { label, value }) => {
    acc[Number(value)] = Number(label);
    return acc;
  },
  {}
);

const inp_discount_insurer = [
  { label: "Omniasig", value: "Omniasig" },
  { label: "Asirom", value: "Asirom" },
  { label: "Allianz", value: "Allianz" },
  { label: "Uniqa", value: "Uniqa" },
  { label: "Generali", value: "Generali" },
  { label: "Groupama", value: "Groupama" },
];

const inp_discount_insurer_flat = inp_discount_insurer.reduce(
  (acc, { label, value }) => {
    acc[value] = label;
    return acc;
  },
  {}
);

export const inp_sicgroup = {
  "SC nefinanciare - S.A.": "bcr.sicCode.SC nefinanciare - S.A.",
  "SC nefinanciare - S.R.L.": "bcr.sicCode.SC nefinanciare - S.R.L.",
  "SC nefinanciare - P.F.A.": "bcr.sicCode.SC nefinanciare - P.F.A.",
  "SC nefinanciare - P.F.I.": "bcr.sicCode.SC nefinanciare - P.F.I.",
  "alte SC nefinanciare": "bcr.sicCode.alte SC nefinanciare",
  "banci si cooperative de credit":
    "bcr.sicCode.banci si cooperative de credit",
  "alti intermediari financiari": "bcr.sicCode.alti intermediari financiari",
  "institutii guvernamentale": "bcr.sicCode.institutii guvernamentale",
  "regii autonome": "bcr.sicCode.regii autonome",
  "intermediari financiari - leasing":
    "bcr.sicCode.intermediari financiari - leasing",
};
// if (inp_contract_type === 'Contract de credit') {

// { label: "SC nefinanciare - S.A.", value: "SC nefinanciare - S.A." },
// { label: "SC nefinanciare - S.R.L.", value: "SC nefinanciare - S.R.L." },
// { label: "SC nefinanciare - P.F.A.", value: "SC nefinanciare - P.F.A." },
// { label: "SC nefinanciare - P.F.I.", value: "SC nefinanciare - P.F.I." },
// { label: "alte SC nefinanciare", value: "alte SC nefinanciare" },
// {
//   label: "banci si cooperative de credit",
//   value: "banci si cooperative de credit",
// },
// {
//   label: "alti intermediari financiari",
//   value: "alti intermediari financiari",
// },
// { label: "institutii guvernamentale", value: "institutii guvernamentale" },
// { label: "regii autonome", value: "regii autonome" },

// {
//   label: "intermediari financiari - leasing",
//   value: "intermediari financiari - leasing",
// },

// const inp_sicgroup_flat = inp_sicgroup.reduce((acc, { label, value }) => {
//   acc[label] = value;
//   return acc;
// }, {});

// } else if (data.inp_contract_type === 'Contract de leasing financiar' || data.inp_contract_type === 'Contract de leasing operational') {
//    ddlValues = [{ label: 'intermediari financiari - leasing', value: 'intermediari financiari - leasing' }];
// }
// values = ddlValues;
//   return ddlValues;
// };

const comparisonType = [
  {
    label: "Offer MTPL",
    value: "euro",
  },
  {
    label: "Offer Operational leasing",
    value: "fleet",
  },
  {
    label: "Offer",
    value: "groupama",
  },
  {
    label: "Offer Total",
    value: "total",
  },
];

export const comparisonType_flat = comparisonType.reduce(
  (acc, { label, value }) => {
    acc[value] = label;
    return acc;
  },
  {}
);

const deliveryType = [
  {
    label: "Interna",
    value: "Interna",
  },
  {
    label: "Externa",
    value: "Externa",
  },
];

const deliveryType_flat = deliveryType.reduce((acc, { label, value }) => {
  acc[value] = label;
  return acc;
}, {});

export const insurerMtpl = {
  omniasig: "bcr.Insurer_OMNIASIG",
  asirom: "bcr.Insurer_ASIROM",
  allianz: "bcr.Insurer_ALLIANZ",
  uniqa: "bcr.Insurer_UNIQA",
  generali: "bcr.Insurer_GENERALI",
  groupama: "bcr.Insurer_GROUPAMA",
  grawe: "bcr.Insurer_GRAWE",
};

export const invertedInsurerMtpl = {
  "bcr.Insurer_OMNIASIG": "omniasig",
  "bcr.Insurer_ASIROM": "asirom",
  "bcr.Insurer_ALLIANZ": "allianz",
  "bcr.Insurer_UNIQA": "uniqa",
  "bcr.Insurer_GENERALI": "generali",
  "bcr.Insurer_GROUPAMA": "groupama",
  "bcr.Insurer_GRAWE": "grawe",
};

export const inp_months = {
  "6": "6",
  "12": "12",
};
const prepTax = (invMap) => {
  const retVal = Object.entries(invMap).map(([key, value]) => {
    return { value: value as string, label: value as string };
  });
  return retVal;
};
export const reverse_romanian_label = (romanianValue) => {
  return inverted_labels[romanianValue]
    ? inverted_labels[romanianValue]
    : romanianValue;
};

export const taxonomy = {
  inp_lessee_type: prepTax(inp_lessee_type),
  inp_vehicle_state: prepTax(inp_vehicle_state),
  inp_vehicle_construction: inp_vehicle_construction,
  inp_vehicle_firstreg: inp_vehicle_firstreg,
  inp_vehicle_construction100: inp_vehicle_construction100,
  inp_vehicle_firstreg100: inp_vehicle_firstreg100,
  inp_vehicle_category: prepTax(inp_vehicle_category),
  inp_vehicle_subcategory: prepTax(inp_vehicle_subcategory),
  inp_vehicle_brand: prepTax(inp_vehicle_brand),
  inp_vehicle_carusage: prepTax(inp_vehicle_carusage),
  inp_policy_payment: prepTax(inp_policy_payment),
  inp_vehicle_fleet: prepTax(inp_vehicle_fleet),
  inp_policy_currency: prepTax(inp_policy_currency),
  inp_contract_type: prepTax(inp_contract_type),
  inp_vehicle_fuel: prepTax(inp_vehicle_fuel),
  inp_asistentarutiera: prepTax(inp_asistentarutiera),
  inp_discount_insurer: inp_discount_insurer,
  inp_lessee_county: prepTax(inp_lessee_county),
  inp_sicgroup: prepTax(inp_sicgroup),
  inp_vehicle_registration: prepTax(inp_vehicle_registration),
  comparisonType: comparisonType,
  insurerCasco: prepTax(insurerCasco),
  deliveryType: deliveryType,
  insurerMtpl: prepTax(insurerMtpl),
  months: prepTax(inp_months),
};

export const taxonomyFlat = {
  inp_lessee_type: inp_lessee_type,
  inp_vehicle_state: inp_vehicle_state,
  inp_vehicle_construction: inp_vehicle_construction_flat100,
  inp_vehicle_construction100: inp_vehicle_construction_flat100,
  inp_vehicle_firstreg: inp_vehicle_firstreg_flat100,
  inp_vehicle_firstreg100: inp_vehicle_firstreg_flat100,
  inp_vehicle_category: inp_vehicle_category,
  inp_vehicle_subcategory: inp_vehicle_subcategory,
  inp_vehicle_brand: inp_vehicle_brand,
  inp_vehicle_carusage: inp_vehicle_carusage,
  inp_policy_payment: inp_policy_payment,
  inp_vehicle_fleet: inp_vehicle_fleet,
  inp_policy_currency: inp_policy_currency,
  inp_contract_type: inp_contract_type,
  inp_vehicle_fuel: inp_vehicle_fuel,
  inp_asistentarutiera: inp_asistentarutiera,
  inp_discount_insurer: inp_discount_insurer_flat,
  inp_lessee_county: inp_lessee_county,
  inp_sicgroup: inp_sicgroup,
  inp_vehicle_registration: inp_vehicle_registration,
  comparisonType: comparisonType_flat,
  insurerCasco: insurerCasco,
  deliveryType: deliveryType_flat,
  insurerMtpl: insurerMtpl,
  months: inp_months,
};
