export enum FieldTypes {
  DATE,
  DATEISO,
  STRING,
  TAXONOMY,
  NUMBER,
  INTEGER,
  BOOLEAN,
  NULL,
  ARRAY,
}

export const typeOfFormItems = {
  selectedMtplInsurers: FieldTypes.STRING,
  selectedCascoInsurers: FieldTypes.STRING,
  include_mtpl_offer: FieldTypes.BOOLEAN,
  offerId: FieldTypes.STRING,
  offerRequestedAt: FieldTypes.DATEISO,
  offerRequestType: FieldTypes.STRING,
  offerRequestedBy: FieldTypes.STRING,
  offerRequestedByName: FieldTypes.STRING,
  inp_lessee_type: FieldTypes.TAXONOMY,
  inp_vehicle_state: FieldTypes.TAXONOMY,
  inp_policy_value: FieldTypes.INTEGER,
  inp_external_contractdate: FieldTypes.DATE,
  inp_vehicle_registration_expiration_date: FieldTypes.DATE,
  inp_vehicle_construction: FieldTypes.TAXONOMY,
  inp_vehicle_firstreg: FieldTypes.TAXONOMY,
  inp_vehicle_category: FieldTypes.TAXONOMY,
  inp_vehicle_brand: FieldTypes.TAXONOMY,
  inp_vehicle_model: FieldTypes.STRING,
  inp_vehicle_maximumgrossweight: FieldTypes.INTEGER,
  inp_vehicle_places: FieldTypes.INTEGER,
  inp_vehicle_carusage: FieldTypes.TAXONOMY,
  inp_policy_payment: FieldTypes.TAXONOMY,
  inp_leasing_monthsinterval: FieldTypes.INTEGER,
  inp_vehicle_fleet: FieldTypes.TAXONOMY,
  inp_policy_currency: FieldTypes.TAXONOMY,
  inp_contract_type: FieldTypes.TAXONOMY,
  inp_vehicle_fuel: FieldTypes.TAXONOMY,
  avarie2: FieldTypes.BOOLEAN,
  avarie3: FieldTypes.BOOLEAN,
  avarie4: FieldTypes.BOOLEAN,
  avarie5: FieldTypes.BOOLEAN,
  avarie6: FieldTypes.BOOLEAN,
  avarie7: FieldTypes.BOOLEAN,
  avarie8: FieldTypes.BOOLEAN,
  // inp_asistentarutiera: FieldTypes.TAXONOMY,
  avarie9: FieldTypes.BOOLEAN,
  avarie10: FieldTypes.BOOLEAN,
  inp_discount_p: FieldTypes.NUMBER,
  inp_discount: FieldTypes.NUMBER,
  inp_discount_insurer: FieldTypes.TAXONOMY,
  inp_increase_p: FieldTypes.NUMBER,
  inp_increase: FieldTypes.NUMBER,
  inp_lessee_lastname: FieldTypes.STRING,
  inp_lessee_firstname: FieldTypes.STRING,
  inp_lessee_company_name: FieldTypes.STRING,
  inp_lessee_uid: FieldTypes.STRING,
  inp_lessee_compreg: FieldTypes.STRING,
  inp_lessee_street: FieldTypes.STRING,
  inp_lessee_housenr: FieldTypes.STRING,
  inp_lessee_city: FieldTypes.STRING,
  inp_lessee_county: FieldTypes.TAXONOMY,
  inp_lessee_mobile: FieldTypes.STRING,
  inp_lessee_email: FieldTypes.STRING,
  inp_vehicle_vin: FieldTypes.STRING,
  inp_vehicle_kw: FieldTypes.INTEGER,
  inp_vehicle_cubiccapacity: FieldTypes.INTEGER,
  inp_lessee_title: FieldTypes.STRING,
  inp_lessee_building: FieldTypes.NUMBER,
  inp_lessee_entrance: FieldTypes.NUMBER,
  inp_lessee_door: FieldTypes.NUMBER,
  inp_lessee_floor: FieldTypes.NUMBER,
  inp_lessee_zip: FieldTypes.NUMBER,
  inp_vehicle_licenseplate: FieldTypes.STRING,
  inp_caen: FieldTypes.STRING,
  inp_start_date: FieldTypes.DATE,
  inp_sicgroup: FieldTypes.TAXONOMY,
  inp_vehicle_id: FieldTypes.STRING,
  inp_vehicle_registration: FieldTypes.TAXONOMY,
  inp_licence_date: FieldTypes.DATE,
  inp_vehicle_subcategory: FieldTypes.TAXONOMY,
  decontare_directa: FieldTypes.STRING,
  inp_driver_compreg: FieldTypes.STRING,
  inp_driver_firstname: FieldTypes.STRING,
  inp_driver_lastname: FieldTypes.STRING,
  inp_driver_number_idcard: FieldTypes.STRING,
  inp_driver_serial_idcard: FieldTypes.STRING,
  inp_driver_phone: FieldTypes.STRING,
  mtpl_commission_broker_p: FieldTypes.NUMBER,
  omniasig_premium: FieldTypes.NUMBER,
  omniasig_promotional_premium: FieldTypes.NUMBER,
  omniasig_partial_premium: FieldTypes.NUMBER,
  omniasig_partial_monthly_premium: FieldTypes.NUMBER,
  omniasig_total_premium: FieldTypes.NUMBER,
  omniasig_total_monthly_premium: FieldTypes.NUMBER,
  asirom_premium: FieldTypes.NUMBER,
  asirom_promotional_premium: FieldTypes.NUMBER,
  asirom_partial_premium: FieldTypes.NUMBER,
  asirom_partial_monthly_premium: FieldTypes.NUMBER,
  asirom_total_premium: FieldTypes.NUMBER,
  asirom_total_monthly_premium: FieldTypes.NUMBER,
  groupama_premium: FieldTypes.NUMBER,
  groupama_promotional_premium: FieldTypes.NUMBER,
  allianz_premium: FieldTypes.NUMBER,
  allianz_promotional_premium: FieldTypes.NUMBER,
  allianz_partial_premium: FieldTypes.NUMBER,
  allianz_partial_monthly_premium: FieldTypes.NUMBER,
  allianz_total_premium: FieldTypes.NUMBER,
  allianz_total_monthly_premium: FieldTypes.NUMBER,
  generali_premium: FieldTypes.NUMBER,
  generali_promotional_premium: FieldTypes.NUMBER,
  generali_partial_premium: FieldTypes.NUMBER,
  generali_partial_monthly_premium: FieldTypes.NUMBER,
  generali_total_premium: FieldTypes.NUMBER,
  generali_total_monthly_premium: FieldTypes.NUMBER,
  uniqa_premium: FieldTypes.NUMBER,
  uniqa_promotional_premium: FieldTypes.NUMBER,
  uniqa_partial_premium: FieldTypes.NUMBER,
  uniqa_partial_monthly_premium: FieldTypes.NUMBER,
  uniqa_total_premium: FieldTypes.NUMBER,
  uniqa_total_monthly_premium: FieldTypes.NUMBER,
  allianz_premium_1: FieldTypes.NUMBER,
  allianz_premium_1d: FieldTypes.NUMBER,
  allianz_premium_6: FieldTypes.NUMBER,
  allianz_premium_6d: FieldTypes.NUMBER,
  allianz_premium_12: FieldTypes.NUMBER,
  allianz_premium_12d: FieldTypes.NUMBER,
  asirom_premium_1: FieldTypes.NUMBER,
  asirom_premium_1d: FieldTypes.NUMBER,
  asirom_premium_6: FieldTypes.NUMBER,
  asirom_premium_6d: FieldTypes.NUMBER,
  asirom_premium_12: FieldTypes.NUMBER,
  asirom_premium_12d: FieldTypes.NUMBER,
  generali_premium_1: FieldTypes.NUMBER,
  generali_premium_1d: FieldTypes.NUMBER,
  generali_premium_6: FieldTypes.NUMBER,
  generali_premium_6d: FieldTypes.NUMBER,
  generali_premium_12: FieldTypes.NUMBER,
  generali_premium_12d: FieldTypes.NUMBER,
  grawe_premium_1: FieldTypes.NUMBER,
  grawe_premium_1d: FieldTypes.NUMBER,
  grawe_premium_6: FieldTypes.NUMBER,
  grawe_premium_6d: FieldTypes.NUMBER,
  grawe_premium_12: FieldTypes.NUMBER,
  grawe_premium_12d: FieldTypes.NUMBER,
  groupama_premium_1: FieldTypes.NUMBER,
  groupama_premium_1d: FieldTypes.NUMBER,
  groupama_premium_6: FieldTypes.NUMBER,
  groupama_premium_6d: FieldTypes.NUMBER,
  groupama_premium_12: FieldTypes.NUMBER,
  groupama_premium_12d: FieldTypes.NUMBER,
  omniasig_premium_1: FieldTypes.NUMBER,
  omniasig_premium_1d: FieldTypes.NUMBER,
  omniasig_premium_6: FieldTypes.NUMBER,
  omniasig_premium_6d: FieldTypes.NUMBER,
  omniasig_premium_12: FieldTypes.NUMBER,
  omniasig_premium_12d: FieldTypes.NUMBER,
  uniqa_premium_1: FieldTypes.NUMBER,
  uniqa_premium_1d: FieldTypes.NUMBER,
  uniqa_premium_6: FieldTypes.NUMBER,
  uniqa_premium_6d: FieldTypes.NUMBER,
  uniqa_premium_12: FieldTypes.NUMBER,
  uniqa_premium_12d: FieldTypes.NUMBER,
  cityinsurance_premium_1: FieldTypes.NUMBER,
  cityinsurance_premium_1d: FieldTypes.NUMBER,
  cityinsurance_premium_6: FieldTypes.NUMBER,
  cityinsurance_premium_6d: FieldTypes.NUMBER,
  cityinsurance_premium_12: FieldTypes.NUMBER,
  cityinsurance_premium_12d: FieldTypes.NUMBER,
  mtplOfferErrorMessage: FieldTypes.STRING,
  insurerMtpl: FieldTypes.TAXONOMY,
  months: FieldTypes.TAXONOMY,
  invoiceMtplId: FieldTypes.STRING,
  invoiceMtplPremium: FieldTypes.NUMBER,
  insurerCasco: FieldTypes.TAXONOMY,
  promotional: FieldTypes.BOOLEAN,
  leasingctrno: FieldTypes.STRING,
  deliveryType: FieldTypes.TAXONOMY,
  validFrom: FieldTypes.DATE,
  invoiceCascoId: FieldTypes.STRING,
  invoiceCascoPremium: FieldTypes.INTEGER,
  invoiceCascoFirstRate: FieldTypes.INTEGER,
  cascoInvoiceIssuedAt: FieldTypes.DATEISO,
  mtplInvoiceIssuedAt: FieldTypes.DATEISO,
  mtplPolicyErrorMessage: FieldTypes.STRING,
  cascoInvoiceIssuedBy: FieldTypes.STRING,
  cascoInvoiceIssuedByName: FieldTypes.STRING,
  mtplInvoiceIssuedBy: FieldTypes.STRING,
  mtplInvoiceIssuedByName: FieldTypes.STRING,
  paymentDate: FieldTypes.DATE,
  policyMtplId: FieldTypes.STRING,
  policyMtplSeries: FieldTypes.NUMBER,
  policyMtplPremium: FieldTypes.NUMBER,
  policyCascoId: FieldTypes.STRING,
  policyCascoTotalId: FieldTypes.STRING,
  policyCascoPartialId: FieldTypes.STRING,
  policyCascoPremium: FieldTypes.INTEGER,
  policyCascoTotalPremium: FieldTypes.NUMBER,
  policyCascoPartialPremium: FieldTypes.NUMBER,
  cascoPolicyIssuedAt: FieldTypes.DATEISO,
  cascoPolicyTotalIssuedAt: FieldTypes.DATEISO,
  cascoPolicyPartialIssuedAt: FieldTypes.DATEISO,
  mtplPolicyIssuedAt: FieldTypes.DATEISO,
  cascoPolicyIssuedBy: FieldTypes.STRING,
  cascoPolicyTotalIssuedBy: FieldTypes.STRING,
  cascoPolicyPartialIssuedBy: FieldTypes.STRING,
  cascoPolicyIssuedByName: FieldTypes.STRING,
  cascoPolicyTotalIssuedByName: FieldTypes.STRING,
  cascoPolicyPartialIssuedByName: FieldTypes.STRING,
  mtplPolicyIssuedBy: FieldTypes.STRING,
  mtplPolicyIssuedByName: FieldTypes.STRING,
  lastModifiedAt: FieldTypes.DATEISO,
  lastModifiedBy: FieldTypes.STRING,
  lastModifiedByName: FieldTypes.STRING,
  status: FieldTypes.STRING,
  comparisonType: FieldTypes.TAXONOMY,
  EURExchangeRate: FieldTypes.NUMBER,
  EURExchangeRateDate: FieldTypes.DATE,
  isRenewed: FieldTypes.BOOLEAN,
  pathToOfferPdf: FieldTypes.STRING,
  pathToGapOfferPdf: FieldTypes.STRING,
  pathToInvoiceCascoPdf: FieldTypes.STRING,
  pathToInvoiceMtplPdf: FieldTypes.STRING,
  pathToPolicyCascoPdf: FieldTypes.STRING,
  pathToPolicyCascoTotalPdf: FieldTypes.STRING,
  pathToPolicyCascoPartialPdf: FieldTypes.STRING,
  pathToPolicyMtplPdf: FieldTypes.STRING,
  inp_mtpl_nfactor: FieldTypes.NUMBER,
  out_mtpl_limits: FieldTypes.NUMBER,
  out_mtpl_limits_message: FieldTypes.STRING,
  highestRole: FieldTypes.STRING,
  isLimitedF100018User: FieldTypes.BOOLEAN,
  ALLOW_PARTIAL_CASCO_GUI_FLAG: FieldTypes.BOOLEAN,
  leasingctrno_mtpl: FieldTypes.STRING,
};
