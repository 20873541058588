import axios, { CancelToken } from "axios";
import { ExpandedColumn } from "../types/columns";
import {
  VehiclesAzureResponse,
  Setting,
  SettingsResponse,
  TaxonomyUADResponse,
  OfferListEntryDto,
} from "../types/types";
import { createAzureFilterParam } from "../utils/azureSearchFilterParam";

const url = process.env.REACT_APP_BASE_URL;
const uadUrl = process.env.REACT_APP_BFF_HOST_UAD;

type LoadVehiclesArgs = {
  vehicleId?: number;
  vehicleStatusCode?: number;
  skip: number;
  top: number;
  search?: string;
  sort: any;
  showArchived?: boolean;
  cancelToken: CancelToken;
  columns: ExpandedColumn[];
  clientIds?: number[];
  offerRequestType: string;
};

export const LoadVehicles = async (args: LoadVehiclesArgs) => {
  const {
    search,
    sort,
    top,
    skip,
    cancelToken,
    columns,
    offerRequestType,
    showArchived,
  } = args;

  let searchUrl = `${url}/GetOfferList?a=1`;

  // if (vehicleId) {
  //   searchUrl += `?fleetId=${vehicleId}`;
  // } else {
  //   searchUrl += "?a=1";
  // }
  if (offerRequestType) {
    searchUrl += `&offerRequestType=${offerRequestType}`;
  }
  if (showArchived === true) {
    searchUrl += `&showArchived=${showArchived}`;
  }
  searchUrl += `&searchCriteria=${search?.replace(
    /&/g,
    encodeURIComponent("&")
  )}*`;

  if (sort && sort.key !== "" && sort.dir !== "") {
    searchUrl += `&$orderby=${sort.key} ${sort.dir}`;
  }
  searchUrl += `&form=json`;
  searchUrl += `&$count=true`;
  searchUrl += `&$top=${top}&$skip=${skip}`;

  let filterStr = createAzureFilterParam(columns as any);

  if (filterStr.length > 0) {
    searchUrl += `&$filter=${filterStr}`;
  }

  const res = await axios.get<VehiclesAzureResponse>(searchUrl, {
    cancelToken,
  });

  return res;
};

export const getAppSettings = async (appID: number) => {
  return await axios.get<SettingsResponse>(`${uadUrl}/appsettings/${appID}`);
};

export const getUADTaxonomy = async () => {
  return await axios.get<TaxonomyUADResponse>(`${uadUrl}/taxonomy`);
};

export const addAppSetting = async (setting: Partial<Setting>) => {
  return await axios.post<Setting[]>(`${uadUrl}/addappsettings`, [setting]);
};

export const deleteAppSetting = async (
  userAppSettingId: Setting["userAppSettingId"]
) => {
  return await axios.delete<Setting[]>(`${uadUrl}/deleteappsettings`, {
    data: [userAppSettingId],
  });
};

export const updateAppSetting = async (setting: any) => {
  return await axios.patch<any[]>(`${uadUrl}/updateappsettings`, [setting]);
};

export const sendAsiromPolicies = async (payload) => {
  return await axios.post(`${url}/PrepareAndSendAsiromZIPFileUrl`, payload);
};

export const sendBCRPolicies = async (payload) => {
  return await axios.post(`${url}/PrepareAndSendBCRExcelFiles`, payload);
};

export const sendExcelFile = async (payload) => {
  return await axios.post(`${url}/RenewOffers`, payload);
};
export const sendExcelNonGrecoFile = async (payload) => {
  return await axios.post(`${url}/HandleMtplOffersFromExcel`, payload);
};

export const getOfferList = async (payload) => {
  return await axios.post(`${url}/LoadOfferListData`, payload);
};

export const getRomanianPropertyFile = async () => {
  const axiosWithoutToken = axios.create({
    headers: {},
  });

  return await axiosWithoutToken.get(`/locales/ro-RO.json`);
};

export type InvalidateOfferParams = {
  offerId: any;
};

export const invalidateOfferReq = async (payload) => {
  return await axios.patch<any>(`${url}/InvalidateOffer`, payload);
};
export const getOffer = async (payload) => {
  return await axios.post<OfferListEntryDto>(`${url}/LoadOfferData`, payload);
};

export const calculateCasco = async (payload) => {
  return await axios.post<OfferListEntryDto>(`${url}/CalculateCasco`, payload);
};

export const calculateCascoAndMtpl = async (payload) => {
  return await axios.post<OfferListEntryDto>(
    `${url}/CalculateCascoAndMtpl`,
    payload
  );
};

export const getVehicleInfo = async (vin: string) => {
  return await axios.get<any>(`${url}/GetVehicleInfo/${vin}`);
};

export const getCompanyInfo = async (cui: string) => {
  return await axios.get<any>(`${url}/GetCompanyInfo/${cui}`);
};

export const getQuotes = async (payload) => {
  return await axios.post<OfferListEntryDto>(`${url}/GetOffer`, payload);
};

export const duplicateOffer = async (payload) => {
  return await axios.post<OfferListEntryDto>(
    `${url}/GetCopyOfOfferData`,
    payload
  );
};

export const invalidateOffer = async (payload) => {
  return await axios.post<OfferListEntryDto>(`${url}/InvalidateOffer`, payload);
};

export const generateCascoInvoice = async (payload) => {
  return await axios.post<OfferListEntryDto>(
    `${url}/GenerateCascoInvoice`,
    payload
  );
};

export const issueCascoPolicy = async (payload) => {
  return await axios.post<OfferListEntryDto>(
    `${url}/IssueCascoPolicy`,
    payload
  );
};

export const issueCascoTotalPolicy = async (payload) => {
  return await axios.post<OfferListEntryDto>(
    `${url}/IssueCascoTotalPolicy`,
    payload
  );
};

export const issueCascoPartialPolicy = async (payload) => {
  return await axios.post<OfferListEntryDto>(
    `${url}/IssueCascoPartialPolicy`,
    payload
  );
};

export const generateMtplInvoice = async (payload) => {
  return await axios.post<OfferListEntryDto>(
    `${url}/GenerateMtplInvoice`,
    payload
  );
};

export const issueMtplPolicy = async (payload) => {
  return await axios.post<OfferListEntryDto>(`${url}/IssueMtplPolicy`, payload);
};

export const repeatOffer = async (payload) => {
  return await axios.post<OfferListEntryDto>(
    `${url}/GetCopyOfOfferData`,
    payload
  );
};

// export const getVehicleByBasicFields = async (
//   VehicleLicensePlate: string,
//   OwnerId: string
// ) => {
//   let reqUrl: string = `${url}/vehicles/licenseplate/${OwnerId}/${VehicleLicensePlate}`;
//   if (VehicleLicensePlate && VehicleLicensePlate.length >= 10) {
//     reqUrl = `${url}/vehicles/vin/${OwnerId}/${VehicleLicensePlate}`;
//   }
//   const res = await axios.get<VehicleInfoDto>(reqUrl);
//   return res;
// };
